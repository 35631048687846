import { useCommonStore } from '@/store/common';
import { toFormat } from '@/utils/number';
import CONST from '@/config/const';
import { toast } from '@/utils/toast';
import { i18n } from '@/lang/i18n';
import moment from 'moment-timezone';
import BigNumber from 'bignumber.js';
import html2canvas from 'html2canvas';
import { findLastIndex, startsWith, includes } from 'lodash';
import { isApp, openLoginPage, callUserInfo, callSubAccount, awakenAppWebview, openNativeBrowser, openWebviewPage, callClosePage } from '@/utils/appBridge';
const common = useCommonStore();

// 获取货币展示精度
export function tokenShowPrecision(assetId: string) {
  const token = common.currencys.find((item: any) => item.tokenId === assetId) || {};
  if (Object.keys(token).length === 0) {
    return 2; // 如果没找到，默认展示两位小数
  } else {
    return token.showPrecision ?? 2; // 如果token内该字段为 null 或 undefined 默认返回 2
  }
}
// 货币以自身展示精度在页面展示
export function displayTokenByShowPrecison(amount: string | number, assetId: string) {
  const precision = tokenShowPrecision(assetId);
  return toFormat(amount, precision);
}
// 金额输入校验限制正则,num->保留多少小数位
export function inputNumReg(value: any, num: number) {
  if (typeof value == 'undefined' || value == '' || value == null) return '';
  if (value[0] == '0' && value?.[1] != '.') {
    value = '0';
    return value;
  }
  value = String(value).replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  if (value.indexOf('.') > 0) {
    const arr = value.split('.');
    if (num && num > 0) {
      value = `${arr[0]}.${arr[1]?.substr(0, num)}`;
    } else {
      value = arr[0];
    }
  }
  return value;
}

// 时间格式化
export function formatDate(time: string | number) {
  if (!time) return '-';
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
}

// utc0时间格式化
export function formatUTCDate(time: string | number) {
  if (!time) return '-';
  return moment.utc(time).format('YYYY-MM-DD HH:mm:ss');
}

// 将中间地址以省略号代替, 地址默认展示前10后5
export function addressWithDots(address: string, left = 10, right = 5) {
  let str = '';
  if (address.length > (left + right)) {
    str = address.substring(0, left) + '...' + address.substring(address.length - right);
  } else {
    str = address;
  }
  return str;
}

/**
 * 将字符串根据换行符 \n \\n分割为数组
 * @param text 原数据
 * @param allowMiddleEmpty 是否保留文本中间的额外换行不过滤
 * @returns
 */
export function splitStrWithEnter(text: string, allowMiddleEmpty = false) {
  const regex = /\\n|\n/;
  // console.log('text,', text);
  const contentArr: any[] = (text || '').split(regex);
  const firstContentIdx = contentArr.findIndex(text => text);
  const lastContentIdx = findLastIndex(contentArr, (text: any) => text);
  // 保留中间的换行，去除头尾
  return allowMiddleEmpty ? contentArr.slice(firstContentIdx, lastContentIdx !== -1 ? lastContentIdx + 1 : contentArr.length) : ((text || '').split(regex).filter(text => text) || []);
}

export function formatMessageDate(timestamp: number) {
  const nowDate = moment(new Date());
  const msgDate = moment(timestamp);

  if (nowDate.isSame(msgDate, 'day')) {
    return msgDate.format('HH:mm');
  } else if (nowDate.isSame(msgDate, 'year')) {
    return msgDate.format('MM/DD');
  } else {
    return msgDate.format('YYYY/MM/DD');
  }
}

// 脱敏
export function desensitization(left: number, right: number, certNumber: string) {
  if (certNumber) {
    let str = '';
    if (certNumber.length > (left + right)) {
      str = certNumber.substring(0, left) + '****' + certNumber.substring(certNumber.length - right);
    } else {
      str = certNumber;
    }
    return str;
  } else {
    return certNumber;
  }

}

// 数字格式化并去0
export function decimalPlaces(value: any, places = 2) {
  return BigNumber(value).decimalPlaces(places).toFormat();
}

// 数字格式化千分位，保留后端返回的数据精度，仅仅只是处理千分位
export function numberToLocaleString(value: any) {
  if (/^\d+$/.test(value)) {
    return String(value).replace(/\d(?=(\d{3})+$)/g, '$&,');
  }
  return String(value).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

/**
 * 16 进制颜色转换成 rgba 格式
 * @param hex 颜色值 #fffff 或 #fff
 * @param alpha 透明度
 * @returns rgba(0, 0, 0, 1)
 */
export function hex2rgb(hex: string, alpha = 1) {
  let str = hex.replace('#', '');
  str = str.length === 3 ? str += str : str.slice(0, 6);

  if (str.length !== 6) {
    return hex;
  }

  const r = parseInt(str.slice(0, 2), 16);
  const g = parseInt(str.slice(2, 4), 16);
  const b = parseInt(str.slice(4, 6), 16);

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
}

/**
 * 获取缓存
 * @param key 缓存key
 */
export function getStorage(key: string): string | null | Record<string, any> {
  const storeItem = localStorage.getItem(key);
  let store = null;

  try {
    store = storeItem ? JSON.parse(storeItem) : null;
  } catch (error) {
    localStorage.removeItem(key);
    store = null;
  }

  return store;
}

/**
* 设置缓存
*
* @param key 缓存key
*/
export function setStorage(key: string, value: Record<string, any> | string | number) {
  localStorage.setItem(key, JSON.stringify(value));
}

// 获取 html 转换成图片的 url
export const html2url = (el: HTMLElement) => {
  return new Promise<string>(resolve => {
    if (!el) {
      return resolve('');
    }

    html2canvas(el, {
      useCORS: true,
      allowTaint: true,
      backgroundColor: null
    }).then(canvas => {
      resolve(canvas.toDataURL());
    }).catch(err => {
      console.log(err);
      resolve('');
    });
  });
};
/**
 * 处理 h5 登录逻辑。
 * @param options 包含登录状态和登录回调函数的对象。
 *      isLogin: boolean - 表示用户是否已登录。
 *      loginCallback: Function - 用户已登录时调用的回调函数。
 */
export function loginLogic(options?: any) {
  // 判断用户是否已登录
  if (options && options.isLogin) {
    // 如果已登录，执行登录回调函数
    options.loginCallback && options.loginCallback();
  } else {
    // 如果未登录，判断当前环境是否为APP
    if (isApp) {
      // 在APP环境中，打开登录页
      openLoginPage();
    } else {
      // 在非APP环境中，唤醒APP的Webview并传入当前页面URL
      const url = window.location.href;
      awakenAppWebview(url);
    }
  }
}

/**
 * h5 根据提供的URL导航到不同目标。
 * @param url 待导航的URL字符串。
 * - 根据`props.data.isApp`的值决定是打开原生浏览器、Web视图、KYC页面还是在当前页面路由。
 * - 如果是在应用内(`isApp`为true)：
 *   - 如果URL以`https://`开头且包含`hashkey.com`，则打开Web视图页面。
 *   - 否则，打开原生浏览器。产品需求web视图打开
 * - 如果是在应用外(`isApp`为false)：
 *   - 直接通过新窗口打开URL。
 */
export function handleH5Url(url:string, router:()=>void) {
  if (isApp) {
    if (startsWith(url, 'https://')) {
      if (includes(url, 'hashkey.com')) {
        openWebviewPage(url); // 在Web视图中打开特定的URL
      } else {
        openNativeBrowser(url); // 在原生浏览器中打开非特定域名的URL
      }
    } else {
      router && router(); // 在当前应用路由中导航到目标URL
    }
  } else {
    window.open(url); // 在新窗口中打开URL，适用于应用外导航
  }
};

/**
 * 检查用户类型是否符合参与活动的条件
 * 该函数首先判断当前环境是否为应用（isApp），
 * 若是，则调用callUserInfo获取用户信息，根据用户类型决定是否能参与活动。
 * 若用户是授权交易商（AUTHORIZED_TRADER），则显示警告信息，并在2秒后关闭页面。
 * 若用户不是授权交易商，会进一步调用callSubAccount判断是否为子账户，
 * 若是子账户，则显示警告信息，并在2秒后关闭页面。
 * 该函数没有参数和返回值。
 */
export function checkUserType() {
  if (isApp) {
    callUserInfo().then((user:any) => {
      // 检查用户类型是否为授权交易商
      if ([CONST.USER_TYPE.AUTHORIZED_TRADER].includes(user.userType)) {
        toast.warning(i18n.global.t('您的用户类型无法参与活动'));
        setTimeout(() => {
          callClosePage();
        }, 2000);
        return;
      }

      callSubAccount().then((isSubAccount:any) => {
        // 检查是否为子账户
        if (isSubAccount === 1) {
          toast.warning(i18n.global.t('此活动仅限主账户参与！'));
          setTimeout(() => {
            callClosePage();
          }, 2000);
          return;
        }
      });
    });
  }
}
